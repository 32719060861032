import React, { useEffect, useState } from "react";
import logo from "../../assets/img/Layer x0020 1.png";
// import google from "../../assets/img/google (1) 1.svg";
// import facebook from "../../assets/img/facebook.png";
import PhoneInput, { formatPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Link } from "react-router-dom";
import eye from "../../assets/img/eye-24-512.png";
import eyeHidden from "../../assets/img/invisible 2.png";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import Select from "../../components/Dropdown/index";
import Expire from "../../components/Expire";
import InputField from "../../components/InputField";
import Loader from "../../components/Loader";
import apiroutes from "../../services/apiroutes";
import { request } from "../../services/apiservice";
import { getAuth, getUser, setAuth, setUser } from "../../services/auth";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import tick from "../../assets/img/tick-circle.png";
import Modal from "../../components/Modal";

export const LoginRegisterComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const loggedInUser = getUser();
  const history = useHistory();
  const [user, setUserDetails] = useState(null);
  const [value, setValue] = useState();
  const [verificationCode, setVerificationCode] = useState("");
  const [email, setEmail] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [gender, setGender] = useState("");
  const [genderNOK, setGenderNOK] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullNameNOK, setFullNameNOK] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const [modalVisible, setVisible] = useState(false);
  const [modalVisibleTwo, setVisibleTwo] = useState(false);

  const token = getAuth();

  useEffect(() => {
    if (loggedInUser === null) {
      history.push("/");
      return false;
    } else {
      setUserDetails(loggedInUser);
      setPhoneNumber(loggedInUser.PhoneNumber);
      setFirstName(loggedInUser?.FirstName);
      setLastName(loggedInUser?.LastName);
      // setFullName(`${loggedInUser.FirstName} ${
      //   loggedInUser.LastName == null ? "" : loggedInUser.LastName
      // }`);
    }
    // eslint-disable-next-line
  }, []);



  const genderOptions = ["Male", "Female"];
  const optionsGender = genderOptions.map((x) => ({ label: x, value: x }));

  const checkEmail = () => {
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (!filter.test(email)) {
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: `Please provide a valid email address`,
        icon: "error",
      });
      return false;
    } else {
      handleSubmitEmailToSendOtp();
    }
  };

  const handleSubmit = () => {
      setError("");
      setResponseMsg("");
      setLoading(true);

      const signDetails = {
        phoneNumber: phoneNumber,
        gender: gender === "Male" ? 0 : 1,
        title: gender === "Male" ? "Mr" : "",
        firstName: firstName,
        lastName: lastName,
        referralCode: referralCode,
        nextOfKinName: fullNameNOK,
        nextOfKinGender: genderNOK === "Male" ? 0 : 1,
        nextOfKinPhoneNumber: value,
      };
      request(apiroutes.UpdateUserProfileV2(), "post", signDetails, token)
        .then((res) => {
          if (res.data.code === 1 || res.data.code === "1") {
            setLoading(false);
            setResponseMsg("");

            Swal.fire({
              showConfirmButton: false,
              timer: 4000,
              text: `${res.data.shortDescription}`,
              icon: "success",
            });
            setVisibleTwo(true);
          } else {
            setLoading(false);
            setResponseMsg(null);
            Swal.fire({
              showConfirmButton: false,
              timer: 4000,
              text: `${res.data.shortDescription}`,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          setResponseMsg(null);
          setLoading(false);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `${err.message}`,
            icon: "error",
          });
        });
    // }
  };
  const handleSubmitEmailToSendOtp = () => {
    // e.preventDefault();
    setError("");
    setResponseMsg("");
    setLoading(true);

    // const nameArr = fullName.split(" ");
    const signDetails = {
      email,
    };

    request(apiroutes.SendOtpToEmail(), "post", signDetails)
      .then((res) => {
        if (res.data.code === 1 || res.data.code === "1") {
          setLoading(false);
          setResponseMsg("");

          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `OTP send to email successfully.`,
            icon: "success",
          });
          setVisibleTwo(false);
          setVisible(true);
        } else {
          setLoading(false);
          setResponseMsg(null);
          // setError(res.message);
          // setError(res.data.ShortDescription);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            // text: `Logged In Successfully`,
            text: `${res.data.shortDescription}`,
            icon: "error",
          });
          // setTimeout(() => setError(""), 6000)
        }
      })
      .catch((err) => {
        // setError(err.data.shortDescription);
        setResponseMsg(null);
        setLoading(false);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `${err.message}`,
          icon: "error",
        });
        // setError(err.message);
        // setTimeout(() => setError(""), 4000)
      });
  };

  const verifyCode = (e) => {
    e.preventDefault();
    setResponseMsg("");
    setLoading(true);
    const verifyDetails = {
      email,
      phoneNumber: phoneNumber,
    };
    request(apiroutes.UpdateUserEmailV2(), "post", verifyDetails, token)
      .then((res) => {
        if (res.data.code == 1) {
          setLoading(false);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `Email Verified successfully, kindly login to continue.`,
            icon: "success",
          });
          history.push("/login");
        } else {
          setLoading(false);
          setResponseMsg(null);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `${res.data.shortDescription}`,
            icon: "error",
          });
          // setTimeout(() => setError(""), 4000)
        }
      })
      .catch((err) => {
        setResponseMsg(null);
        setLoading(false);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          // text: `Phone Number Verified`,
          text: `${err.message}`,
          icon: "error",
        });
        // setError(err.data.shortDescription);
        // setTimeout(() => setError(""), 4000)
      });
  };

  const toggleModalClose = () => {
    setVisible(true);
  };
  const toggleModalCloseTwo = () => {
    setVisibleTwo(true);
  };

  const width = 500;
  const height = 350;
  const modalTitle = "Enter Verification Code";
  const modalPar = `A Verification Code has been sent to ${email}.`;
  const modalBody = (
    <div>
      <label htmlFor="email" className="label-auth">
        Verification Code
      </label>
      <InputField
        type="text"
        placeholder="Enter verification code"
        onChangeMethod={(e) => setVerificationCode(e.target.value)}
      />
      <br />
      <br />
      <p>
        Didn’t receive the code?{" "}
        <span
          style={{ cursor: "pointer", color: "rgba(86, 204, 242, 0.9)" }}
          onClick={() => handleSubmitEmailToSendOtp()}
        >
          Resend code
        </span>{" "}
        or{" "}
        <span
          style={{ cursor: "pointer", color: "rgba(86, 204, 242, 0.9)" }}
          onClick={() => {
            setVisible(false);
            setVisibleTwo(true);
          }}
        >
          change email
        </span>
      </p>
      <Button
        text={loading ? <Loader dark={false} /> : "Verify"}
        handleButtonClick={verifyCode}
        disabled={!verificationCode}
        type="button"
      />
    </div>
  );

  const modalTitleTwo = "Verify your email";
  const modalBodyTwo = (
    <div className="">
      {/* <img src={tick} alt="" className="tick-img" /> */}
      <br />
      <br />
      <div className="">
        <label htmlFor="email" className="label-auth">
          Email
        </label>
        <InputField
          type="text"
          placeholder="Enter email"
          onChangeMethod={(e) => setEmail(e.target.value)}
          value={email}
        />
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 offset-md-3 text-center mt-5">
          <Button
            // text={loading ? <Loader dark={false} /> : "Sign Up"}
            text={
              loading ? (
                <div
                  style={{
                    display: "flex",
                    margin: "0 auto",
                    justifyContent: "center",
                    alignItems: "center",
                    // width: "200px",
                    transform: "translateX(-10px)",
                  }}
                >
                  <Loader dark={false} />{" "}
                  <span
                    style={{
                      display: "inline-block",
                      transform: "translateX(-50px)",
                    }}
                  >
                    Processing
                  </span>
                </div>
              ) : (
                "Save Details"
              )
            }
            handleButtonClick={checkEmail}
            btnstyle={{ cursor: "pointer" }}
            type="button"
            disabled={!email}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        width={width}
        height={height}
        visible={modalVisible}
        title={modalTitle}
        paragraph={modalPar}
        body={modalBody}
        handleClose={toggleModalClose}
      />

      <Modal
        width={width}
        height={height}
        visible={modalVisibleTwo}
        title={modalTitleTwo}
        body={modalBodyTwo}
        handleClose={toggleModalCloseTwo}
      />
      <div className="d-md-flex h-md-100">
        <div className="col-md-5 bg-indigo h-md-100">
          <div className="row">
            <div className="col-md-12">
              <div className="this-position">
                <Link to="/">
                  <img src={logo} alt="" className="home-logo" />
                </Link>
                <h1>Revolutionizing road transportation in Africa</h1>
                <p>
                  GIGM is a technologically powered road transport platform
                  providing MOBILITY services to people across Africa
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 h-md-100">
          <div className="d-md-flex align-items-center justify-content-center h-md-100">
            <div className="pt-0">
              <div className="auth-section">
                <div className="row justify-content-md-center">
                  <div className="col-md-12">
                    {error && (
                      <Expire delay={3000}>
                        <Alert
                          className="alert text-center alert-danger"
                          text={error}
                        />
                      </Expire>
                    )}
                    {responseMsg && !error && (
                      <Expire delay={3000}>
                        <Alert
                          className="alert text-center alert-primary"
                          text={responseMsg}
                        />
                      </Expire>
                    )}
                  </div>
                </div>
                <h1>Personalise your profile</h1>
                <br />
                {/* <div className="text-center">
                  <button className="btn-social">
                    <img src={google} alt="" />
                    &nbsp; &nbsp; Google
                  </button>
                  &nbsp; &nbsp;
                  <button className="btn-social">
                    <img src={facebook} alt="" />
                    &nbsp; &nbsp; Facebook
                  </button>
                </div> */}
                {/* <span className="breaking-or">Or</span> */}
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="firstName" className="label-auth">
                      First Name
                    </label>
                    <InputField
                      type="text"
                      placeholder="Enter firstname"
                      onChangeMethod={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      // disabled
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="lastName" className="label-auth">
                      Last Name
                    </label>
                    <InputField
                      type="text"
                      placeholder="Enter fullname"
                      onChangeMethod={(e) => setLastName(e.target.value)}
                      value={lastName}
                      // disabled
                    />
                  </div>
                </div>
                <br />
                {/* <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="email" className="label-auth">
                      Email
                    </label>
                    <InputField
                      type="email"
                      placeholder="Example@gmail.com"
                      onChangeMethod={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                 
                </div> */}
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="password" className="label-auth">
                      Gender
                    </label>
                    <Select
                      options={optionsGender}
                      handleChange={(e) => setGender(e.value)}
                      placeholder={
                        loggedInUser.Gender === 0
                          ? "Male"
                          : "Female" || "Select gender"
                      }
                      value={gender}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="referralCode" className="label-auth">
                      Referral Code
                    </label>
                    <InputField
                      type="text"
                      placeholder="ASDF;LKJ"
                      onChangeMethod={(e) => setReferralCode(e.target.value)}
                      value={referralCode}
                    />
                  </div>
                </div>
                <br />
                <h1>Next of Kin Information</h1>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="email" className="label-auth">
                      Full Name
                    </label>
                    <InputField
                      type="text"
                      placeholder="Enter fullname"
                      onChangeMethod={(e) => setFullNameNOK(e.target.value)}
                      value={fullNameNOK}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="password" className="label-auth">
                      Gender
                    </label>
                    <Select
                      options={optionsGender}
                      handleChange={(e) => setGenderNOK(e.value)}
                      placeholder="Select gender"
                      value={genderNOK}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="password" className="label-auth">
                      Phone Number
                    </label>
                    <PhoneInput
                      placeholder="Enter phone number"
                      defaultCountry="NG"
                      value={value}
                      onChange={setValue}
                    />
                  </div>
                  <></>
                </div>
                <br />
                <br />
                <div className="row">
                  <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                    <Button
                      // text={loading ? <Loader dark={false} /> : "Sign Up"}
                      text={
                        loading ? (
                          <div
                            style={{
                              display: "flex",
                              margin: "0 auto",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "200px",
                              transform: "translateX(-10px)",
                            }}
                          >
                            <Loader dark={false} />{" "}
                            <span
                              style={{
                                display: "inline-block",
                                transform: "translateX(-50px)",
                              }}
                            >
                              Processing
                            </span>
                          </div>
                        ) : (
                          "Save Details"
                        )
                      }
                      handleButtonClick={handleSubmit}
                      btnstyle={{ cursor: "pointer" }}
                      type="button"
                      disabled={
                        !(
                          (gender)
                        )
                      }
                    />
                    {/* <p className="forgot-password">
                      Already have an Account? <Link to="/login">Sign In</Link>
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginRegisterComponent;

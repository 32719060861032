import React, { useEffect, useState } from "react";
import logo from "../../assets/img/Layer x0020 1.png";
// import google from "../../assets/img/google (1) 1.svg";
// import facebook from "../../assets/img/facebook.png";
import PhoneInput, { formatPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Link } from "react-router-dom";
import eye from "../../assets/img/eye-24-512.png";
import eyeHidden from "../../assets/img/invisible 2.png";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import Select from "../../components/Dropdown/index";
import Expire from "../../components/Expire";
import InputField from "../../components/InputField";
import Loader from "../../components/Loader";
import apiroutes from "../../services/apiroutes";
import { request } from "../../services/apiservice";
import { getAuth, setAuth, setUser } from "../../services/auth";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import tick from "../../assets/img/tick-circle.png";
import Modal from "../../components/Modal";

export const RegisterComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordField, setPasswordField] = useState("password");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordFieldConfirm, setPasswordConfirmField] = useState("password");
  const [value, setValue] = useState();
  const [password, setPassword] = useState("");
  const [otpDate, setOtpDate] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPhoneInput, setShowPhoneInput] = useState(true);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [fullName, setFullName] = useState("");
  const [terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const [modalVisible, setVisible] = useState(false);
  const [modalVisibleTwo, setVisibleTwo] = useState(false);
  const history = useHistory();
  const [termsError, setTermsError] = useState("");

  const token = getAuth();

  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
    setPasswordField(passwordField === "text" ? "password" : "text");
    setError(false);
    setResponseMsg(null);
  };

  const showConfirmPasswordToggle = () => {
    setShowConfirmPassword(!showConfirmPassword);
    setPasswordConfirmField(
      passwordFieldConfirm === "text" ? "password" : "text"
    );
    setError(false);
    setResponseMsg(null);
  };

  const genderOptions = ["Male", "Female"];
  const optionsGender = genderOptions.map((x) => ({ label: x, value: x }));

  const handlePhoneVerification = () => {
    setShowPhoneInput(false);
  };

  const handlePhoneSendOtp = () => {
    // e.preventDefault();
    if (value.length < 14 || value.length > 14) {
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: "Only Nigerian numbers are allowed. Please provide a Nigerian number.",
        icon: "error",
      });
      return;
    }
    setResponseMsg("");
    setLoading(true);
    const verify = {
      phoneNumber: value,
    };
    request(apiroutes.SendOtpToPhone(), "post", verify, null)
      .then((res) => {
        if (res.data.code === 1 || res.data.code === "1") {
          setLoading(false);
          setOtpDate(res.data?.data?.dateTime);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `OTP Sent Successfully.`,
            icon: "success",
          });
          setVisible(true);
        } else {
          setLoading(false);
          setResponseMsg(null);
          Swal.fire({
            showConfirmButton: false,
            timer: 6000,
            text: `${res.data.shortDescription}`,
            icon: "error",
          });
        }
      })
      .catch((err) => {
        setResponseMsg(null);
        setLoading(false);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: err.response?.data?.shortDescription ||
          `Error validating your request, Please try again!`,
          icon: "error",
        });
      });
  };

  const handleSubmit = () => {
    // e.preventDefault();
    if (password === confirmPassword) {
      setError("");
      setResponseMsg("");
      setLoading(true);

      // const nameArr = fullName.split(" ");
      const signDetails = {
        phoneNumber: formatPhoneNumber(value).replace(/\s/g, ""),
        password: password,
        clientId: "desktop.site@gigm.com",
        countryId: 1,
      };

      request(apiroutes.RegisterV2(), "post", signDetails)
        .then((res) => {
          if (res.data.code == 1) {
            setLoading(false);
            setResponseMsg("");

            Swal.fire({
              showConfirmButton: false,
              timer: 4000,
              text: `Registration successfull, Please login to continue.`,
              // text: `${res.data.shortDescription}`,
              icon: "success",
            });
            history.push("/login");
          } else {
            setLoading(false);
            setResponseMsg(null);
            Swal.fire({
              showConfirmButton: false,
              timer: 4000,
              text: res.data?.shortDescription ||
            `Error validating your request, Please try again!`,
              // text: `Password should be a minimum of 6 characters, a minimum of one alphabet, number and special character.`,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          // if (err.message == "Request failed with status code 400") {
          //   setResponseMsg(null);
          //   setLoading(false);
          //   Swal.fire({
          //     showConfirmButton: false,
          //     timer: 6000,
          //     text: `Password should be a minimum of 6 characters, a minimum of one alphabet, number and special character.`,
          //     icon: "error",
          //   });
          //   return;
          // }
          setResponseMsg(null);
          setLoading(false);
          Swal.fire({
            showConfirmButton: false,
            timer: 6000,
            text: err.response?.data?.shortDescription ||
            `Error validating your request, Please try again!`,
            icon: "error",
          });
        });
    }
    // else if (password.length <= 6 || confirmPassword.length <= 6) {
    //   Swal.fire({
    //     showConfirmButton: false,
    //     timer: 4000,
    //     text: `Minimum of 6 characters required for password`,
    //     icon: "error",
    //   });
    // }
    else {
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: `Password Doesn't Match`,
        icon: "error",
      });
    }
  };

  const verifyCode = (e) => {
    e.preventDefault();
    setResponseMsg("");
    setLoading(true);
    const verifyDetails = {
      username: value,
      otpCode: verificationCode,
      dateTime: otpDate,
    };
    request(apiroutes.CustomerVerifyOtp(), "post", verifyDetails, token)
      .then((res) => {
        if (res.data.code === 1 || res.data.code === "1") {
          setLoading(false);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `Phone Number Verified`,
            // text: `${res.data.ShortDescription}`,
            icon: "success",
          });
          setShowPhoneInput(false);
          setVisible(false);
          setShowPasswordInput(true);
        } else {
          setLoading(false);
          setResponseMsg(null);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            // text: `Password Doesn't Match`,
            text: `${res.data.shortDescription}`,
            icon: "error",
          });
          // setTimeout(() => setError(""), 4000)
        }
      })
      .catch((err) => {
        setResponseMsg(null);
        setLoading(false);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          // text: `Phone Number Verified`,
          text: err.response?.data?.shortDescription ||
          `Error validating your request, Please try again!`,
          icon: "error",
        });
        // setError(err.data.shortDescription);
        // setTimeout(() => setError(""), 4000)
      });
  };

  const toggleModalClose = () => {
    setVisible(true);
  };
  const toggleModalCloseTwo = () => {
    setVisibleTwo(true);
  };

  const width = 500;
  const height = 350;
  const modalTitle = "Enter Verification Code";
  const modalPar = `A Verification Code has been sent to ${value}.`;
  const modalBody = (
    <div>
      <label htmlFor="email" className="label-auth">
        Enter your verification Code
      </label>
      <InputField
        type="text"
        placeholder="Verification code"
        onChangeMethod={(e) => setVerificationCode(e.target.value)}
      />
      <br />
      <br />
      <p>
        Didn’t receive the code?{" "}
        <span
          style={{ cursor: "pointer", color: "rgba(86, 204, 242, 0.9)" }}
          onClick={() => {
            handlePhoneSendOtp();
          }}
        >
          Resend code
        </span>{" "}
        or{" "}
        <span
          style={{ cursor: "pointer", color: "rgba(86, 204, 242, 0.9)" }}
          onClick={() => setVisible(false)}
        >
          change number
        </span>
      </p>
      <Button
        text={loading ? <Loader dark={false} /> : "Verify"}
        handleButtonClick={verifyCode}
        disabled={!verificationCode}
        type="button"
      />
    </div>
  );

  const modalTitleTwo = "Phone Number Verified";
  const modalBodyTwo = (
    <div className="text-center">
      <img src={tick} alt="" className="tick-img" />
      <br />
      <br />
      <p className="success-password">
        <Link to="/">Go to Dashboard</Link>
      </p>
    </div>
  );

  return (
    <div>
      <Modal
        width={width}
        height={height}
        visible={modalVisible}
        title={modalTitle}
        paragraph={modalPar}
        body={modalBody}
        handleClose={toggleModalClose}
      />

      <Modal
        width={width}
        height={height}
        visible={modalVisibleTwo}
        title={modalTitleTwo}
        body={modalBodyTwo}
        handleClose={toggleModalCloseTwo}
      />
      <div className="d-md-flex h-md-100">
        <div className="col-md-5 bg-indigo h-md-100">
          <div className="row">
            <div className="col-md-12">
              <div className="this-position">
                <Link to="/">
                  <img src={logo} alt="" className="home-logo" />
                </Link>
                <h1>Revolutionizing road transportation in Africa</h1>
                <p>
                  GIGM is a technologically powered road transport platform
                  providing MOBILITY services to people across Africa
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 h-md-100">
          <div className="d-md-flex align-items-center justify-content-center h-md-100">
            <div className="pt-0">
              <div className="auth-section">
                <div className="row justify-content-md-center">
                  <div className="col-md-12">
                    {error && (
                      <Expire delay={3000}>
                        <Alert
                          className="alert text-center alert-danger"
                          text={error}
                        />
                      </Expire>
                    )}
                    {responseMsg && !error && (
                      <Expire delay={3000}>
                        <Alert
                          className="alert text-center alert-primary"
                          text={responseMsg}
                        />
                      </Expire>
                    )}
                  </div>
                </div>
                {showPhoneInput && (
                  <>
                    <div className="col-md-12 text-center">
                      <h1>Create your account</h1>
                      <br />

                      <h2>
                        <b>Hello There,</b>
                      </h2>
                      <p>
                        Welcome to the GIGM app. Please fill in your details to
                        create an account.
                      </p>
                      <br />
                      <div className="row">
                        <div className="col-md-12">
                          {/* <label htmlFor="password" className="label-auth">
                        Phone Number
                      </label> */}
                          <PhoneInput
                            placeholder="Enter phone number"
                            defaultCountry="NG"
                            value={value}
                            onChange={setValue}
                          />
                        </div>
                      </div>
                    </div>

                    <br />
                    <br />
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <label className="container-check">
                          &nbsp; &nbsp; By Signing Up, I Agree to GIGM's{" "}
                          <Link to="/terms">Terms and Conditions</Link> and{" "}
                          <Link to="/Privacy-Policy">Privacy Policy</Link>
                          <input
                            type="checkbox"
                            onChange={(e) => setTerms(e.target.checked)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <Expire delay={5000}>
                      {!terms ? (
                        <p style={{ color: "red", textAlign: "center" }}>
                          {termsError}
                        </p>
                      ) : (
                        ""
                      )}
                    </Expire>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                        <Button
                          // text={loading ? <Loader dark={false} /> : "Sign Up"}
                          text={
                            loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  margin: "0 auto",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "200px",
                                  transform: "translateX(-10px)",
                                }}
                              >
                                <Loader dark={false} />{" "}
                                <span
                                  style={{
                                    display: "inline-block",
                                    transform: "translateX(-50px)",
                                  }}
                                >
                                  Processing
                                </span>
                              </div>
                            ) : (
                              "Sign Up"
                            )
                          }
                          handleButtonClick={handlePhoneSendOtp}
                          btnstyle={{ cursor: "pointer" }}
                          type="button"
                          disabled={!(value && terms)}
                        />
                        <p className="forgot-password">
                          Already have an Account?{" "}
                          <Link to="/login">Sign In</Link>
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {showPasswordInput && (
                  <>
                    <div className="col-md-12 text-center">
                      {/* <h1>Create account</h1>
                       <br /> */}

                      <h2>
                        <b>Create Password</b>
                      </h2>
                      <br />
                      <p>Please fill in your preferred password</p>
                      <br />
                      <div className="row">
                        <div className="col-md-6">
                          <label htmlFor="password" className="label-auth">
                            Password
                          </label>
                          <div className="pass-wrapper">
                            <InputField
                              type={passwordField}
                              placeholder="Enter password"
                              onChangeMethod={(e) =>
                                setPassword(e.target.value)
                              }
                              value={password}
                              autoComplete="new-password"
                            />

                            {showPassword ? (
                              <img
                                src={eye}
                                alt=""
                                onClick={showPasswordToggle}
                                className="passwordVisible img-size"
                              />
                            ) : (
                              <img
                                src={eyeHidden}
                                alt=""
                                onClick={showPasswordToggle}
                                className="passwordVisible"
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="confirmPassword"
                            className="label-auth"
                          >
                            Confirm Password
                          </label>
                          <div className="pass-wrapper">
                            <InputField
                              type={passwordFieldConfirm}
                              placeholder="Confirm password"
                              onChangeMethod={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                              value={confirmPassword}
                            />

                            {showConfirmPassword ? (
                              <img
                                src={eye}
                                alt=""
                                onClick={showConfirmPasswordToggle}
                                className="passwordVisible img-size"
                              />
                            ) : (
                              <img
                                src={eyeHidden}
                                alt=""
                                onClick={showConfirmPasswordToggle}
                                className="passwordVisible"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />
                    <br />
                    <div className="">
                      <div
                        className="col-md-6 text-center"
                        style={{ margin: "0 auto" }}
                      >
                        <label htmlFor="" className="label-auth">
                          <b>Password must include;</b>
                        </label>
                        <ul style={{ marginLeft: "0", padding: "0" }}>
                          <li style={{ display: "inline-block" }}>
                            Minimum of 8 characters
                          </li>
                          <li style={{ display: "inline-block" }}>
                            At least one uppercase letter
                          </li>
                          <li style={{ display: "inline-block" }}>
                            At least one lowercase letter
                          </li>
                          <li style={{ display: "inline-block" }}>
                            At least one special character
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                        <Button
                          text={
                            loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  margin: "0 auto",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "200px",
                                  transform: "translateX(-10px)",
                                }}
                              >
                                <Loader dark={false} />{" "}
                                <span
                                  style={{
                                    display: "inline-block",
                                    transform: "translateX(-50px)",
                                  }}
                                >
                                  Processing
                                </span>
                              </div>
                            ) : (
                              "Get Started"
                            )
                          }
                          handleButtonClick={handleSubmit}
                          btnstyle={{ cursor: "pointer" }}
                          type="button"
                          disabled={!(password && confirmPassword)}
                        />
                        <p className="forgot-password">
                          Already have an Account?{" "}
                          <Link to="/login">Sign In</Link>
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RegisterComponent;
